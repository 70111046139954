import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridReadyEvent, ICellRendererParams, IRowNode, RowSelectedEvent, SortChangedEvent } from 'ag-grid-community';
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import { Role, UserAdmin, UserAdminCollection, UserAdminWithTestCount, UserSearchEvent } from '../../../shared/models/user.model';
import { StatisticsService } from '../../../shared/services/statistics.service';
import { UsersRequestParams, UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'app-dashboard-user-filter',
    templateUrl: './dashboard-user-filter.component.html',
    styleUrls: ['./dashboard-user-filter.component.scss']
})
export class DashboardUserFilterComponent extends AGTableBase {

    @Input() from: string;
    @Input() to: string;
    @Input() selectedUsers: UserAdmin[] = [];
    @Output() changeBatch = new EventEmitter<UserAdmin[]>();
    @ViewChild('searchInput') searchInput; 

    paginationPageSize: number = 5000;
    loading: boolean = false;
    totalRowsCount: number = 0;
    owner: string;
    searchString: string;
    paymentTypeFilter: string;
    rowData: UserAdminWithTestCount[] = [];
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'User', field: 'username',
            flex: 0.6, headerTooltip: 'User email',
            cellRenderer: (params: ICellRendererParams) => {
                const _: UserAdmin = params.data;
                const icon = `<span class="icon-postpaid"></span>`;
                if (_.paymentType === 'PREPAID') {
                    return `<div class="one-line">${_.email}</div>`;
                }
                return `<div class="one-line">${_.email} ${icon}</div>`;
            }
        },
        {
            headerName: 'Tests', field: 'testCount',
            flex: 0.1, headerTooltip: 'Test count',
            sortable: true, sort: 'desc',
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.data) {
                    return '';
                }
                return params.data.testCount || 0;
            }
        },
        {
            headerName: 'Owner', field: 'ownerUserEmail',
            flex: 0.3, headerTooltip: 'Account manager',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.ownerUserEmail ? _.ownerUserEmail.split('@')[0] : 'N/A'}</div>`;
            }
        }
    ];

    constructor(private userService: UsersService, private statistics: StatisticsService) {
        super();
    }

    update() {
        this.loading = true;
        const userSearchParams: UsersRequestParams = new UsersRequestParams();
        userSearchParams.page = 0;
        userSearchParams.size = 5000;

        const userSearchEvent: UserSearchEvent = {
            roles: [Role.MAIN],
            enabled: true
        };
        this.userService.all(userSearchParams, userSearchEvent).subscribe((users: UserAdminCollection) => {
            this.rowData = users.content;
            this.totalRowsCount = this.rowData.length;
            const ids = this.rowData.map(user => user.id);
            this.statistics.getTestCountForUserIds(this.from, this.to, ids).subscribe((data: { [key: number]: number }) => {
                this.rowData = this.rowData.map(user => {
                    user.testCount = data[user.id] || 0;
                    return user;
                }).filter(user => user.testCount > 0);
                this.loading = false;
                this.setPreSelectedRows();
                this.gridApi.refreshCells();
            });
        });
    }

    isFilterActive(filterType: string): boolean {
        switch (filterType) {
            case 'owner':
                const owner = this.owner?.trim();
                return owner?.length > 0;
            case 'paymentType':
                return this.paymentTypeFilter === 'prepaid' || this.paymentTypeFilter === 'postpaid';
            default:
                return false;
        }
    }

    setPreSelectedRows() {
        const preSelectedUserIds = this.selectedUsers.map(user => user.id);
        this.gridApi.forEachNode((node) => {
            if (preSelectedUserIds.includes(node.data.id)) {
                node.setSelected(true);
            }
        });
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
    }

    onPageChange(event: any): void {
        this.gridApi.paginationGoToPage(event - 1);
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.changeBatch.emit(selectedRows);
    }

    onOwnerClear() {
        this.owner = '';
        this.gridApi.onFilterChanged();
    }

    onOwnerSubmit() {
        this.gridApi.onFilterChanged();
    }

    onSearchStringChange(event: any) {
        this.searchString = event.target.value;
        this.gridApi.onFilterChanged();
    }

    onPaymentTypeSelect(paymentType: string) {
        if (this.paymentTypeFilter === paymentType) {
            this.paymentTypeFilter = null;
            this.gridApi.onFilterChanged();
            return;
        }
        this.paymentTypeFilter = paymentType;
        this.gridApi.onFilterChanged();
    }

    onClearAllFilters() {
        this.searchString = '';
        this.searchInput.nativeElement.value = '';  
        this.owner = '';
        this.paymentTypeFilter = null;
        this.gridApi.onFilterChanged();
    }

    getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return params.data.id;
    };

    isExternalFilterPresent = (): boolean => {
        return true;
    };

    clearAllRowsSelection() {
        this.gridApi.deselectAll();
    }

    doesExternalFilterPass = (node: IRowNode<UserAdmin>): boolean => {
        if (node.data) {
            const passesOwnerFilter = this.owner
                ? node.data.ownerUserEmail?.toLowerCase()?.includes(this.owner.toLowerCase())
                : true;

            const passesSearchStringFilter = this.searchString
                ? node.data.email?.toLowerCase()?.includes(this.searchString.toLowerCase())
                : true;

            const passesPaymentTypeFilter = this.paymentTypeFilter
                ? node.data.paymentType === this.paymentTypeFilter?.toUpperCase()
                : true;

            // Combine all filters
            return passesOwnerFilter && passesSearchStringFilter && passesPaymentTypeFilter;
        }
        return true;
    };

}
