
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    TemplateRef,
    Output,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import {
    MoTestDetails,
    MoTestDetailsStatus,
    MoTestingResult,
    MoTestingResultsCollection,
    MoTestingSearch
} from "../../../shared/models/mo-testing.model";
import { AuthUser, Role } from '../../../shared/models/user.model';
import { BrowserUtils } from "../../../shared/services/browser-utils";
import { CustomUtils } from '../../../shared/services/custom-utils';
import { ExportService } from '../../../shared/services/export.service';
import { LiveNumberTestingService } from '../../../shared/services/live-number-testing.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { AllRequestParams, MoActionData, MoTestingService } from "../../../shared/services/mo-testing.service";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { MoSearchFormComponent } from '../mo-search-form/mo-search-form.component';
import { MoResultsTableActionsComponent } from './mo-results-table-actions/mo-results-table-actions.component';
import { Clipboard } from '@angular/cdk/clipboard';
declare var moment: any;


@Component({
    selector: 'app-mo-results-table',
    templateUrl: 'mo-results-table.component.html',
    outputs: ['actions'],
    inputs: ['filter'],
    styleUrls: ['mo-results-table.component.scss'],
})

export class MoResultsTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @ViewChild('textModalTpl', { read: TemplateRef, static: false }) textModalTpl: any;
    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: any;
    isAdmin = false;
    user: AuthUser;
    intervalTimer: any;
    filter: Object = {};
    actions = new EventEmitter();
    onAfterDelete = new EventEmitter();
    @Output() onRepeat = new EventEmitter<MoTestingResult>();
    searchParams: MoTestingSearch;
    MAX_TOTAL_ITEMS_COUNT = 5000;
    storageContext = 'mo-results';
    textModalHtml: string;
    exportSize = 500;
    exportSizes = [500, 1000, 3000, 5000, 'All'];
    exportData: any = {};
    exportSpinner = false;
    details: MoTestDetails & { test?: MoTestingResult, statuses: MoTestDetailsStatus[] & { showPdu?: boolean, parsedPdu?: string }[] };
    detailsModal: DialogRef;
    loading: boolean = false;

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: 'ID', field: 'id',
            minWidth: 90, maxWidth: 90,
            hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Date', field: 'createdAtFormat',
            minWidth: 90, maxWidth: 90,
            headerTooltip: 'Date and time when the test was triggered, in the browser’s time zone',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_);
            },
        },
        {
            headerName: 'UTC Date', field: 'utcTimestamp',
            minWidth: 90, maxWidth: 90,
            headerTooltip: 'UTC date for test created date.', hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return CustomUtils.formatDateIntoUTC(_.createdAt)
            },
        },
        {
            headerName: 'Country(MCC)/Network(MNC)', field: 'originDto',
            flex: 1.5,
            headerTooltip: 'The origin network from which the MO test was sent',
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="two-lines">${params.value ? this.formatCountry(params.value) : ''}</div>`;
            }
        },
        {
            headerName: 'Sent/DLR status', field: 'testStatus',
            minWidth: 90, maxWidth: 90, flex: 0.6,
            headerTooltip: 'Sent - the SMS submission status we receive from our testing device, DLR status - the delivery status that we receive from the MNO',
            cellClass: 'd-flex justify-content-center align-items-center',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatStatus(_)}</div>`;
            }
        },
        {
            headerName: 'Origin number', field: 'originDto',
            headerTooltip: 'Origin number - Test number from which the test SMS was sent',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<span class="two-lines">${_.originDto ? _.originDto.phone : ''}</span>`;
            }
        },
        {
            headerName: 'Destination number', field: 'destination',
            headerTooltip: 'Number to which the test SMS was sent',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.destination}</div>`;
            }
        },
        {
            headerName: 'Text', field: 'textDelivered',
            flex: 1.5,
            headerTooltip: 'Text of the sent/submitted SMS',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatText(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Sent/DLR delay', field: 'receiptDelay',
            headerTooltip: 'Delays in getting the testing device’s ‘Sent’ and MNO’s DLR status',
            minWidth: 90, maxWidth: 90, flex: 0.6,
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return `<div class="two-lines">${this.formatDelay(row)}</div>`
            },
        },
        {
            headerName: 'User', field: 'user',
            flex: 0.5,
            headerTooltip: 'User that triggered the test',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.user ? (_.user.username ? _.user.username : _.user.email) : 'N/A'}<div>`;
            }
        },
        {
            headerName: 'Price', field: 'price',
            minWidth: 70, maxWidth: 70,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return _.price > 0 ? _.price / 1000 : 0;
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: MoResultsTableActionsComponent,
        }
    ];

    rowData!: MoTestingResult[];
    // ng grid


    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public service: MoTestingService,
        public liveService: LiveNumberTestingService,
        public userService: UsersService,
        public localStorage: LocalStorageService,
        public modal: ModalService,
        public exportService: ExportService,
        private clipBoard: Clipboard
    ) {
        super();
        userService.getAuthUser().then(user => {
            this.isAdmin = user.role === Role.ADMIN;
            this.user = user;
            this.init();
        }).catch(e => { });
    }

    init() {
        let hideColumns = [];
        if (!this.isAdmin && !this.user.impersonated) {
            hideColumns.push('price');
        }

        if (this.user.role === 'subaccount' && !this.user.showAllResults) {
            hideColumns.push('user');
        }
        this.columnDefs = this.columnDefs.filter(c => !hideColumns.includes(c.headerName));
        this.paginationPageSize = this.localStorage.get(`mo_results_table_component_size_${this.user.id}`) || 20;
        const savedUserSearchDetails = this.localStorage.get(`mo_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.searchParams = savedUserSearchDetails;
        }
        this.intervalTimer = setInterval(() => {
            if (this.loading) {
                return;
            }
            this.update(false);
        }, 1000 * 20);
    }

    ngOnInit() {
        this.actionSubscription = this.service.action$.subscribe(
            action => this.onAction(action)
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.localStorage.set(`mo_table_state_${this.user.id}`, this.gridApi.getColumnState());
        });
    }

    update(loading = true) {
        if (!this.user.enabled) { return; }
        this.loading = loading;
        let params = new AllRequestParams();
        params.size = this.paginationPageSize;
        params.page = this.currentPageNumber - 1;
        const success = (data: MoTestingResultsCollection) => {
            data.content.forEach((_, index) => {
                _.createdAtFormat = moment(_.createdAt).format('DD/MM/YY HH:mm:ss').split(" ");
            });
            this.rowData = data.content;
            this.totalRowsCount = data.totalElements;
            this.loading = false;
        };
        const error = (error) => {
            this.loading = false;
            this.notificationService.error({
                title: 'MO testing',
                message: 'An error occurred while loading the results',
                serviceName: 'MOTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        };

        if (this.searchParams) {
            this.service.searchResults(params, this.searchParams).subscribe({ next: success, error: error });
        } else {
            this.service.results(params).subscribe({ next: success, error: error });
        }
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`mo_results_table_component_size_${this.user.id}`, size);
        this.update();
    }

    onAction(event: MoActionData) {
        this.actions.emit(event);
        if (event.name === 'details') {
            this.detailsModal = this.modal.alert().dialogClass('modal-dialog large-modal').component(this.detailsModalTpl).open();
            this.detailsModal.onDestroy.subscribe(() => {
                this.details = null;
            });
            this.service.testDetails(event.row.id).subscribe({
                next: (details) => {
                    this.details = { ...details, test: event.row };
                    const testCreatedTimestamp = moment(this.details.test.createdAt);
                    const ttl = this.details.ttl;
                    this.details.statuses.forEach(st => {
                        const statusTimestamp = moment(st.serverReceivedAt);
                        const actualDelay = statusTimestamp.diff(testCreatedTimestamp, 'seconds');
                        if (ttl < actualDelay) {
                            st.isStatusAfterExpired = true;
                        }
                    });
                },
                error: e => {
                    this.detailsModal.close(true);
                    this.notificationService.error({
                        title: 'MO testing',
                        message: 'An error occurred while loading the details',
                        serviceName: 'MOTC',
                        requestMessage: e.statusText,
                        requestCode: e.status,
                        ts: e.timestamp ? e.timestamp : null
                    });
                }
            });
        }
        if (event.name === 'repeat') {
            console.log(event.row);
            this.onRepeat.emit(event.row);
        }
    }

    formatDelay(row) {
        let text = [];
        if (typeof row.sentDelay === 'number') {
            let delay = CustomUtils.secToHumanTime(row.sentDelay);
            text.push(row.sentDelay >= 300 ? `<span class="text-accent-a2">${delay}</span>` : delay);
        }
        if (typeof row.receiptDelay === 'number') {
            let delay = CustomUtils.secToHumanTime(row.receiptDelay);
            text.push(row.receiptDelay >= 300 ? `<span class="text-accent-a2">${delay}</span>` : delay);
        }
        return `<span>${text.join('/')}</span>`;
    }

    onCellClick(event: CellClickedEvent) {
        if (event.column.getColId() === 'textDelivered') {
            this.textModalHtml = BrowserUtils.sanitizeHTML(event.data.textDelivered);
            if (event.data.telqId) {
                const telqIdContent = `<span title="Test ID Text" class="text-success">${event.data.telqId}</span>`;
                this.textModalHtml = this.textModalHtml.replace(event.data.telqId, telqIdContent);
            }
            this.modal.alert().dialogClass('modal-dialog small-modal').component(this.textModalTpl).open().onDestroy.subscribe(() => {
            });
        }
    }

    onClickPdu(status: MoTestDetailsStatus & { showPdu?: boolean, parsedPdu?: string }) {
        if (!status.pdu) {
            return;
        }
        status.parsedPdu = '';
        status.showPdu = !status.showPdu;
        if (status.showPdu) {
            status.parsedPdu = this.service.parsePdu(status.pdu);
        }
    }

    formatText(row): string {
        if (!row.textDelivered) {
            return BrowserUtils.escapeHtml(row.textDelivered);
        }
        let text = String(row.textDelivered);
        let maxLen = 30;
        let len = text.length;
        if (len >= maxLen) {
            text = text.substr(0, maxLen) + '...';
        }
        text = BrowserUtils.escapeHtml(text);
        return `<div class="two-lines clickable"><span>${text}</span></div>`;
    }

    onClickExport() {
        this.exportSize = 0;
        this.exportSpinner = false;
        this.exportData = {};
    }

    export(limit) {
        this.exportSpinner = true;
        let params = this.searchParams;
        const ids = MoSearchFormComponent.getSaveUserIds(this.localStorage, this.user);
        if (!this.searchParams && ids.length) {
            params = { userIds: ids }
        }

        this.exportService.export('mo', limit === 'All' ? null : limit, params ? params : {}).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Live number testing',
                message: 'An error occurred while export tests',
                serviceName: 'NTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }

    formatDate(_: MoTestingResult) {
        return `<div class="two-lines">${_.createdAtFormat[1]} <br>${_.createdAtFormat[0]}</div>`
    }

    formatCountry(c, icon = true) {
        return this.service.formatDestination(c, icon);
    }

    formatStatus(row: MoTestingResult) {
        const sentStatuses = {
            wait: '<span class="icon-wait spin" title="Wait"></span>',
            sent: '<span class="icon-done text-sucess-s2" title="Sent"></span>',
            sent_error: '<span class="icon-negative text-error-r3" title="Negative"></span>',
            expired: '<span class="far fa-frown text-error-r3" title="Expired"></span>',
            internal_error: '<span class="icon-bug-red p-0_2 text-error-r3" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>',
            unknown_error: '<span class="icon-enroute-unknown text-accent-a2"  title="Unknown"></span>',
            test_number_offline: '<span class="icon-negative text-accent-a2" title="Test number offline"></span>',
            test_number_not_available: '<span class="icon-test-number-not-available text-accent-a2" title="Test number not available"></span>',
            network_offline: '<span class="icon-network-offline text-error-r3" title="Network offline"></span>',
        }
        const dlrStatuses = {
            wait: '<span class="icon-wait spin" title="Wait"></span>',
            delivered: '<span class="far fa-smile text-sucess-s2" title="Delivered"></span>',
            canceled: '<span class="icon-no-dlr-received text-error-r3" title="Rejected"></span>',
            undeliverable: '<span class="far fa-frown text-error-r3" title="Undeliverable"></span>',
            expired: '<span class="far fa-frown text-error-r3" title="Expired"></span>',
            internal_error: '<span class="icon-bug-red p-0_2 text-error-r3" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>',
            unknown_error: '<span class="icon-enroute-unknown text-accent-a2"  title="Unknown"></span>',
            test_number_offline: '<span class="icon-negative text-accent-a2" title="Test number offline"></span>',
            test_number_not_available: '<span class="icon-test-number-not-available text-accent-a2" title="Test number not available"></span>',
            network_offline: '<span class="icon-network-offline text-error-r3" title="Network offline"></span>',
        }
        let sentStatus = row.sentStatus ? row.sentStatus.toLowerCase() : 'wait';
        let dlrStatus = row.dlrStatus ? row.dlrStatus.toLowerCase() : 'wait';
        if (sentStatus === 'internal_error') {
            return `<div class="h-100 d-flex align-items-center justify-content-center fs-2">${sentStatuses[sentStatus]}</div>`;
        }
        if (sentStatus === dlrStatus && sentStatus in sentStatuses) {
            return `<div class="h-100 d-flex align-items-center justify-content-center fs-2">${sentStatuses[sentStatus]}</div>`;
        }
        const value = [
            '<div class="h-100 d-flex align-items-center justify-content-center fs-2">',
            sentStatus in sentStatuses ? sentStatuses[sentStatus] : sentStatus,
            '<span class="fs-3 mx-0_2 fw-normal">/</span>',
            dlrStatus in dlrStatuses ? dlrStatuses[dlrStatus] : dlrStatus,
            '</div>'
        ].join('');
        return value;
    }

    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        const result: (MenuItemDef)[] = [
            {
                name: 'Test Case Details ',
                icon: '<span class="icon-tests-settings fs-4"></span>',
                action: () => {
                    this.service.announceAction({ name: 'details', row: currentRowClicked, column: 'action' })
                },
            },
            {
                name: 'Copy',
                icon: '<span class="icon-copy fs-4"></span>',
                action: () => {
                    const renderedValue = this.getRenderedCellValue(params);
                    this.clipBoard.copy(renderedValue ? renderedValue : currentRowClicked.id);
                },
            },
        ];
        return result;
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        const columnState = this.localStorage.get(`mo_table_state_${this.user.id}`);
        this.update();
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    ngOnDestroy() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
        this.actionSubscription?.unsubscribe();
    }

}
