import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import {
    AllProfitDetails, DailyProfitGraph,
    IService,
    ProfitDetails,
    StatisticSummary, TestCountGraph,
    TestCountItem, TestCountServiceGraph, TestCountUserGraph, TestCountUserGraphUser
} from '../models/statistics.model';
import { BasicUser, Role, UserTestCount } from '../models/user.model';
import countryToId from './geo/country-to-id';
import { RestUtils } from './rest-utils';
import { UsersService } from './users.service';
declare var moment: any;

@Injectable()
export class StatisticsService {

    httpClient: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    static colors = [
        '#7A5AFD',  // purple
        '#00A473',  // green
        '#FA7575',  // red
        '#ff8708',  // orange
        '#59C4FF',  // blue
        '#E007FF',  // pink
        '#59c4ff',  // light blue
        '#FFD900',  // yellow
        '#00A473',  // light green
        '#110736'   // dark blue
    ];

    static chartFontFamily = '"Inter", sans-serif';
    static chartCrosshair: Highcharts.AxisCrosshairOptions = { color: '#311e7c', dashStyle: 'Dash' };
    static chartTooltip: Highcharts.TooltipOptions = { shared: true, backgroundColor: "#311e7c", style: { color: '#fff' }, borderRadius: 15, borderWidth: 0, shadow: false };

    static services = {
        ntc: 'Live number testing',
        mtc: 'Manual testing',
        mo: 'MO',
        scheduler: 'Scheduler',
        cli: 'CLI testing',
    };

    constructor(httpClient: HttpClient, private userService: UsersService) {
        this.httpClient = httpClient;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    summary(): Observable<StatisticSummary> {
        let url = this.utils.buildUrl('ROLE/usr/user-statistics/summary');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.httpClient.get<StatisticSummary>(url, options);
    }

    private processTestCountData(items: TestCountItem[], units: string): TestCountGraph {
        let billed = [];
        let submitted = [];
        let min = 0;
        let max = 0;

        items.forEach((item, index) => {
            const ts = Date.parse(item.date);
            if (min === 0) { min = ts; }
            if (index === items.length - 1) { max = ts; }
            billed.push([ts, item.testBilled]);
            submitted.push([ts, item.testSubmitted]);
        });

        const days = StatisticsService.getDays(min, max);

        return {
            billed: billed,
            submitted: submitted,
            tickInterval: StatisticsService.getTickInterval(units),
            labelStep: StatisticsService.getStep(days, units),
            days: days,
            min: min,
            max: max
        };
    }

    testCount(from: string, to: string, users: BasicUser[]): Observable<TestCountGraph> {
        const isAdmin = this.userService.authUser.role === Role.ADMIN;
        const units = StatisticsService.getUnitsFromRange(Date.parse(from), Date.parse(to));
        const urlToCall = isAdmin ? 'ROLE/aphr/mainaccount-test-count' : 'ROLE/aphr/test-count';
        const userIds = users.map(_ => _.id);
        const url = this.utils.buildUrl(urlToCall, {
            from,
            to,
            aggregateUnits: units,
        });
        const options = this.utils.getHttpHeaderOptions(this.headers);

        const httpCall = isAdmin
            ? this.httpClient.post<TestCountItem[]>(url, userIds, options)
            : this.httpClient.get<TestCountItem[]>(url, options);

        return httpCall.pipe(map(items => this.processTestCountData(items, units)));
    }

    processServices(
        data: { [key: string]: TestCountItem[] },
        units: 'DAYS' | 'MONTH'
    ) {
        let result: TestCountServiceGraph = {
            services: [],
            tickInterval: StatisticsService.getTickInterval(units),
            labelStep: 0,
            days: 0,
            min: 0,
            max: 0,
        };
        const services = StatisticsService.services;
        let ntcObj: IService = { serviceName: '', ui: [], api: [] },
            mtcObj: IService = { serviceName: '', ui: [], api: [] },
            cliObj: IService = { serviceName: '', ui: [], api: [] },
            moObj: IService = { serviceName: '', ui: [], api: [] },
            schedulerObj: IService = { serviceName: '', ui: [], api: [] };

        for (const [serviceCode, serviceLabel] of Object.entries(services)) {
            if (serviceCode === 'ntc') {
                ntcObj['serviceName'] = serviceLabel;
                ntcObj['ui'] = data.ntc_ui.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
                ntcObj['api'] = data.ntc_api.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
            }
            if (serviceCode === 'mtc') {
                mtcObj['serviceName'] = serviceLabel;
                mtcObj['ui'] = data.mtc_ui.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
                mtcObj['api'] = data.mtc_api.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
            }
            if (serviceCode === 'cli') {
                cliObj['serviceName'] = serviceLabel;
                cliObj['ui'] = data.cli_ui.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
                cliObj['api'] = data.cli_api.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
            }
            if (serviceCode === 'mo') {
                moObj['serviceName'] = serviceLabel;
                moObj['ui'] = data.mo.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
            }
            if (serviceCode === 'scheduler') {
                schedulerObj['serviceName'] = serviceLabel;
                schedulerObj['ui'] = data.scheduler.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.testBilled]);
            }
        }
        result.services = [ntcObj, mtcObj, cliObj, moObj, schedulerObj];
        result.days = StatisticsService.getDays(result.min, result.max);
        result.labelStep = StatisticsService.getStep(result.days, units);
        return result;
    }


    services(from: string, to: string, users: BasicUser[]): Observable<TestCountServiceGraph> {
        const isAdmin = this.userService.authUser.role === Role.ADMIN;
        const units = StatisticsService.getUnitsFromRange(Date.parse(from), Date.parse(to));
        const urlToCall = isAdmin ? 'ROLE/aphr/services/mainaccount-test-count' : 'ROLE/aphr/services/test-count';
        let url = this.utils.buildUrl(urlToCall, {
            from: from,
            to: to,
            aggregateUnits: units,
        });
        const userIds = users.map(_ => _.id);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        const httpCall = isAdmin ?
            this.httpClient.post<{ [key: string]: TestCountItem[] }>(url, userIds, options) :
            this.httpClient.get<{ [key: string]: TestCountItem[] }>(url, options);
        return httpCall.pipe(map(data => this.processServices(data, units))
        );
    }


    users(from: string, to: string, users: BasicUser[]): Observable<TestCountUserGraph> {
        const units = StatisticsService.getUnitsFromRange(Date.parse(from), Date.parse(to));
        let url = this.utils.buildUrl('ROLE/aphr/subaccounts/test-count', {
            from: from,
            to: to,
            aggregateUnits: units,
            searchUserIds: users.map(_ => _.id).join(',')
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.httpClient.get<{ [key: string]: TestCountItem[] }>(url, options).pipe(
            map((data) => {
                let result: TestCountUserGraph = {
                    users: [],
                    tickInterval: StatisticsService.getTickInterval(units),
                    labelStep: 0,
                    days: 0,
                    min: 0,
                    max: 0,
                };
                let userId: keyof typeof data;
                for (userId in data) {
                    const items = data[userId];
                    let billed = [];
                    let submitted = [];
                    items.forEach(item => {
                        const ts = Date.parse(item.date);
                        if (ts < result.min || result.min === 0) { result.min = ts; }
                        if (ts > result.max) { result.max = ts; }
                        billed.push([ts, item.testBilled]);
                        submitted.push([ts, item.testSubmitted]);
                    });
                    const sortValue = submitted.map(_ => _[1]).reduce((sum, a) => sum + a, 0)
                    result.users.push({
                        userId: parseInt(userId),
                        sortValue: sortValue,
                        userTitle: '',
                        billed: billed,
                        submitted: submitted,
                        visible: true
                    });
                }
                result.days = StatisticsService.getDays(result.min, result.max);
                result.labelStep = StatisticsService.getStep(result.days, units);
                return result;
            })
        )
    }

    private processWorldData(data: {
        countryName: string;
        testCount: number;
    }[]): [string, number][] {
        let countries = new Map<string, number>();
        data.forEach(item => {
            const countryName = item.countryName;
            const testsCount = item.testCount;
            const countryId = countryToId[countryName];
            if (!countryId) { return; }
            let count = countries.has(countryId) ? countries.get(countryId) : 0;
            countries.set(countryId, count + testsCount);
        });
        let results: [string, number][] = [];
        countries.forEach((tests, country) => {
            return results.push([country, tests]);
        });
        return results;
    }

    world(from: string, to: string, users: BasicUser[]): Observable<[string, number][]> {
        const isAdmin = this.userService.authUser.role === Role.ADMIN;
        const urlToCall = isAdmin ? 'ROLE/aphr/mainaccount-countries' : 'ROLE/aphr/countries';
        let url = this.utils.buildUrl(urlToCall, {
            from: from,
            to: to,
        });
        const userIds = users.map(_ => _.id);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        const httpCall = isAdmin ?
            this.httpClient.post<{ countryName: string, testCount: number }[]>(url, userIds, options) :
            this.httpClient.get<{ countryName: string, testCount: number }[]>(url, options);
        return httpCall.pipe(map(data => this.processWorldData(data)));
    }

    static mergeUsers(users: TestCountUserGraphUser[]): TestCountUserGraphUser {
        let mergedUser: TestCountUserGraphUser = {
            visible: true,
            userTitle: '',
            userId: 0,
            sortValue: 0,
            billed: [],
            submitted: []
        };
        let billed = new Map<number, number>();
        let submitted = new Map<number, number>();
        users.forEach(u => {
            u.billed.forEach(billedValue => {
                let ts = billedValue[0], count = billedValue[1], countExists = billed.get(ts);
                billed.set(ts, countExists ? (countExists + count) : count);
            });
            u.submitted.forEach(submittedValue => {
                let ts = submittedValue[0], count = submittedValue[1], countExists = submitted.get(ts);
                mergedUser.sortValue += count;
                submitted.set(ts, countExists ? (countExists + count) : count);
            });
        });
        billed.forEach((ts, count) => { mergedUser.billed.push([ts, count]) });
        submitted.forEach((ts, count) => { mergedUser.submitted.push([ts, count]) });
        return mergedUser;
    }


    static getTickInterval(units: string): number {
        return (units === 'DAYS' ? 24 * 3600 : 30 * 24 * 3600) * 1000;
    }

    static getDays(min: number, max: number): number {
        return (max - min) / (24 * 3600 * 1000);
    }

    static getStep(days: number, units: string): number {
        if (units === 'MONTH') {
            return 1;
        }
        let step = 1;
        if (days > 30) {
            step = 15;
        } else if (days > 14) {
            step = 7;
        }
        return step;
    }

    static getUnitsFromRange(min: number, max: number): ('DAYS' | 'MONTH') {
        const days = StatisticsService.getDays(min, max);
        if (days > 90) {
            return 'MONTH';
        }
        return 'DAYS';
    }

    private processDailyProfitData(_: {
        date: string,
        totalProfit: number,
        profitMt: number,
        profitMo: number,
        profitCli: number
    }[], units: "MONTH" | "DAYS"): DailyProfitGraph {
        const min = Math.min(..._.map(_ => moment.utc(_.date).startOf('day').valueOf()));
        const max = Math.max(..._.map(_ => moment.utc(_.date).startOf('day').valueOf()));
        const days = StatisticsService.getDays(min, max);
        const unit = StatisticsService.getUnitsFromRange(min, max);
        const step = StatisticsService.getStep(days, unit);
        const tickInterval = StatisticsService.getTickInterval(unit);
        return {
            total: _.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.totalProfit]),
            mt: _.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.profitMt]),
            mo: _.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.profitMo]),
            cli: _.map(_ => [moment.utc(_.date).startOf('day').valueOf(), _.profitCli]),
            tickInterval: tickInterval,
            labelStep: step,
            days: days,
            min: min,
            max: max
        };
    }

    dailyProfit(from: string, to: string, userIds: number[]): Observable<DailyProfitGraph> {
        const units = StatisticsService.getUnitsFromRange((new Date(from).getTime()), (new Date(to).getTime()));
        const isAdmin = this.userService.authUser.role === Role.ADMIN;
        const url = this.utils.buildUrl(isAdmin ? 'ROLE/aphr/user-statistics/mainaccount-daily-profit' : 'ROLE/aphr/user-statistics/daily-profit', {
            from: from,
            to: to,
            aggregateUnits: units
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        type Frame = {
            date: string,
            totalProfit: number,
            profitMt: number,
            profitMo: number,
            profitCli: number
        };
        const httpCall = isAdmin ?
            this.httpClient.post<Frame[]>(url, userIds, options) :
            this.httpClient.get<Frame[]>(url, options);
        return httpCall.pipe(map(_ => this.processDailyProfitData(_, units)));
    }

    profitDetails(start, end): Observable<AllProfitDetails[]> {
        let params = {
            startDate: '',
            endDate: ''
        };
        if (start) {
            params.startDate = start.replace('T', ' ');
        } else {
            delete params.startDate;
        }
        if (end) {
            params.endDate = end.replace('T', ' ');
        } else {
            delete params.endDate;
        }

        let urlMt = this.utils.buildUrl('ROLE/usr/user-statistics/details', Object.assign({ serviceType: 'MT' }, params));
        let urlMo = this.utils.buildUrl('ROLE/usr/user-statistics/details', Object.assign({ serviceType: 'MO' }, params));
        let urlCli = this.utils.buildUrl('ROLE/usr/user-statistics/details', Object.assign({ serviceType: 'VOICE' }, params));
        let options = this.utils.getHttpHeaderOptions(this.headers);

        let allProfitDetails: { [key: string]: AllProfitDetails; } = {}
        const add = (details: ProfitDetails, type: 'mt' | 'mo' | 'cli') => {
            const key = `${details.userEmail}${type}${details.pricePerTest}`
            if (!allProfitDetails[key]) {
                allProfitDetails[key] = {
                    userId: details.userId,
                    billingType: details.billingType,
                    userEmail: details.userEmail,
                    ownerEmail: details.ownerEmail,
                    convertedByEmail: details.convertedByEmail,
                    mtPricePerTest: 0,
                    mtTestCount: 0,
                    moPricePerTest: 0,
                    moTestCount: 0,
                    cliPricePerTest: 0,
                    cliTestCount: 0,
                    profit: 0
                }
            }
            allProfitDetails[key].profit += details.profit;
            switch (type) {
                case "mo":
                    allProfitDetails[key].moPricePerTest = details.pricePerTest;
                    allProfitDetails[key].moTestCount += details.testCount;
                    break
                case "mt":
                    allProfitDetails[key].mtPricePerTest = details.pricePerTest;
                    allProfitDetails[key].mtTestCount += details.testCount;
                    break
                case "cli":
                    allProfitDetails[key].cliPricePerTest = details.pricePerTest;
                    allProfitDetails[key].cliTestCount += details.testCount;
                    break
            }
        };

        return forkJoin([
            this.httpClient.get<ProfitDetails[]>(urlMt, options),
            this.httpClient.get<ProfitDetails[]>(urlMo, options),
            this.httpClient.get<ProfitDetails[]>(urlCli, options)
        ]).pipe(map(results => {
            results[0].forEach(_ => add(_, 'mt'));
            results[1].forEach(_ => add(_, 'mo'));
            results[2].forEach(_ => add(_, 'cli'));

            let userToProfitDetails: { [key: string]: AllProfitDetails[]; } = {};
            Object.values(allProfitDetails).forEach(d => {
                let key = d.userEmail;
                if (!userToProfitDetails[key]) { userToProfitDetails[key] = []; }
                userToProfitDetails[key].push(d);
            });

            let mergedResults: AllProfitDetails[] = [];
            Object.values(userToProfitDetails).forEach(d => {
                this.mergeAllProfitDetails(d).forEach(_ => mergedResults.push(_));
            });

            return mergedResults.map(d => {
                if (d.mtPricePerTest) { d.mtPricePerTest = d.mtPricePerTest / 1000; }
                if (d.moPricePerTest) { d.moPricePerTest = d.moPricePerTest / 1000; }
                if (d.cliPricePerTest) { d.cliPricePerTest = d.cliPricePerTest / 1000; }
                return d;
            });
        }));
    }

    private mergeAllProfitDetails(details: AllProfitDetails[]): AllProfitDetails[] {
        if (!details.length) { return []; }
        let detailsGroupByType: { [key: string]: AllProfitDetails[]; } = {
            mt: [],
            mo: [],
            cli: [],
        };
        details.forEach(d => {
            if (d.mtPricePerTest) { detailsGroupByType.mt.push(d); }
            if (d.moPricePerTest) { detailsGroupByType.mo.push(d); }
            if (d.cliPricePerTest) { detailsGroupByType.cli.push(d); }
        });
        let results: AllProfitDetails[] = [];
        let count = Math.max(detailsGroupByType.mt.length, detailsGroupByType.mo.length, detailsGroupByType.cli.length);
        let i = 0;
        while (count) {
            let mt = detailsGroupByType.mt[i] ? detailsGroupByType.mt[i] : null;
            let mo = detailsGroupByType.mo[i] ? detailsGroupByType.mo[i] : null;
            let cli = detailsGroupByType.cli[i] ? detailsGroupByType.cli[i] : null;
            count--;
            i++;
            let result = { ...mt, ...mo, ...cli }
            result.profit = 0
            if (mt) {
                result.mtPricePerTest = mt.mtPricePerTest;
                result.mtTestCount = mt.mtTestCount;
                result.profit += mt.profit;
            }
            if (mo) {
                result.moPricePerTest = mo.moPricePerTest;
                result.moTestCount = mo.moTestCount;
                result.profit += mo.profit;
            }
            if (cli) {
                result.cliPricePerTest = cli.cliPricePerTest;
                result.cliTestCount = cli.cliTestCount;
                result.profit += cli.profit;
            }
            results.push(result);
        }

        return results;
    }

    profitDetailsExport(start, end) {
        let data = {
            filter: {
                startDate: start,
                endDate: end
            }
        };

        let url = this.utils.buildUrl('ROLE/exp/export-sync/profit-details', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.httpClient.post(url, data, { ...options, responseType: 'blob' });
    }

    private framesToTimeline<T extends { startAt: number }>(daysBeforeNow: number, frames: T[]): T[] {
        daysBeforeNow--;
        let timeline = {}, nowDate = new Date(), secInDay = 3600 * 24;
        nowDate.setUTCHours(0, 0, 0);
        let current = Math.floor(
            Date.UTC(nowDate.getUTCFullYear(), nowDate.getUTCMonth(), nowDate.getUTCDate(), 0, 0, 0) / 1000
        ) - (daysBeforeNow * secInDay);
        for (let i = 0; i < daysBeforeNow; i++) {
            let finishAt = current += secInDay;
            timeline[current] = {
                startAt: current,
                finishAt: finishAt,
            };
            current = finishAt
        }
        if (!frames.length) {
            return Object.values(timeline);
        }
        let framesMap = {};
        frames.forEach(_ => { framesMap[_.startAt] = _ });
        for (let startAt in timeline) {
            if (framesMap[startAt]) {
                timeline[startAt] = framesMap[startAt]
            }
        }
        return Object.values(timeline);
    }

    getTestCountForUserIds(from: string, to: string, userIds: number[]): Observable<{ [key: number]: number }> {
        let url = this.utils.buildUrl('ROLE/aphr/user-test-count', {
            from: from,
            to: to
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.httpClient.post<UserTestCount[]>(url, userIds, options).pipe(map(data => {
            let result = {};
            data.forEach(item => {
                if (!result[item.mainaccountId]) {
                    result[item.mainaccountId] = item.testBilled;
                } else {
                    result[item.mainaccountId] += item.testBilled;
                }
            });
            return result;
        }));
    }
}
