import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DashboardIndexComponent } from './app/dashboard/dashboard-index/dashboard-index.component';
import { NetworkFormComponent } from './app/network-info/network-form/network-form.component';
import { NetworkIndexComponent } from './app/network-info/network-index/network-index.component';
import { NetworkTableComponent } from './app/network-info/network-table/network-table.component';
import { CountryFormComponent } from './app/network-info/country-form/country-form.component';
import { CountryIndexComponent } from './app/network-info/country-index/country-index.component';
import { CountryTableComponent } from './app/network-info/country-table/country-table.component';
import { SchedulerCreateComponent } from './app/scheduler/scheduler-create/scheduler-create.component';
import { SchedulerFormComponent } from './app/scheduler/scheduler-form/scheduler-form.component';
import { SchedulerIndexComponent } from './app/scheduler/scheduler-index/scheduler-index.component';
import { SchedulerReportsIndexComponent } from './app/scheduler/scheduler-reports-index/scheduler-reports-index.component';
import { SchedulerReportsTableComponent } from './app/scheduler/scheduler-reports-table/scheduler-reports-table.component';
import { SchedulerTableComponent } from './app/scheduler/scheduler-table/scheduler-table.component';
import { SessionSupplierIndexComponent } from './app/sessions-suppliers/session-suppliers-index/session-supplier-index.component';
import { SessionSupplierTableComponent } from './app/sessions-suppliers/session-suppliers-table/session-supplier-table.component';
import { SessionCreateComponent } from './app/sessions-suppliers/sessions/session-create/session-create.component';
import { SessionFormComponent } from './app/sessions-suppliers/sessions/session-form/session-form.component';
import { SessionUpdateComponent } from './app/sessions-suppliers/sessions/session-update/session-update.component';
import { SupplierCreateComponent } from './app/sessions-suppliers/suppliers/supplier-create/supplier-create.component';
import { SupplierFormComponent } from './app/sessions-suppliers/suppliers/supplier-form/supplier-form.component';
import { SupplierUpdateComponent } from './app/sessions-suppliers/suppliers/supplier-update/supplier-update.component';
import { InputFile } from './app/shared/components/input/input.file';
import { FileSizePipe } from './app/shared/pipes/fileSize.pipe';

// data service
import { CallbackHistoryService } from './app/shared/services/callback-history.service';
import { CountryService } from './app/shared/services/country.service';
import { LocalStorageService } from './app/shared/services/localStorage.service';
import { ManualNumberTestingService } from './app/shared/services/manual-number-testing.service';
import { NetInfoService } from './app/shared/services/net-info.service';
import { SchedulerService } from './app/shared/services/scheduler.service';
import { ValidationService } from './app/shared/services/validation.service';

// ui service
import { BreadcrumbService } from './app/shared/components/breadcrumbs/breadcrumbs';
import { NotificationService } from './app/shared/services/notification.service';

// components
import { AppComponent } from './app/app/app.component';
import { AppscriptsBalanceFormComponent } from './app/appscripts/appscripts-balance-form/appscripts-balance-form.component';
import { AppscriptsPaymentMethodFormComponent } from './app/appscripts/appscripts-payment-method/appscripts-payment-method-form/appscripts-payment-method-form.component';
import { AppscriptsPaymentMethodTableComponent } from './app/appscripts/appscripts-payment-method/appscripts-payment-method-table/appscripts-payment-method-table.component';
import { Breadcrumbs } from './app/shared/components/breadcrumbs/breadcrumbs';
import { FormErrors } from './app/shared/components/error/formErrors';
import { InputDateTime } from './app/shared/components/input/input.dateTime';
import { InputDuration } from './app/shared/components/input/input.duration';
import { InputErrors } from './app/shared/components/input/input.errors';
import { InputSearch } from './app/shared/components/input/input.search';
import { InputSearchDatePeriod } from './app/shared/components/input/input.search.datePeriod';
import { PaginationComponent } from './app/shared/components/pagination/pagination.component';
import { Progressbar } from './app/shared/components/progress/progressbar.component';

// pipes
import { DurationPipe } from './app/shared/pipes/duration.pipe';
import { FilterTextPipe } from './app/shared/pipes/filter.text.pipe';
import { SortPipe } from './app/shared/pipes/sort.pipe';

// config
import { routes } from './app/routes';
import { LiveNumberTestingService } from './app/shared/services/live-number-testing.service';
import { environment } from './environments/environment';

import { ApiSettingsComponent } from "./app/api/api-settings/api-settings.component";
import { CallbackHistoryTableComponent } from "./app/api/callback-history/callback-history-table/callback-history-table.component";
import { AuditFilterFormComponent } from "./app/audit-log/audit-filter-form/audit-filter-form.component";
import { AuditLogIndexComponent } from "./app/audit-log/audit-log-index/audit-log-index.component";
import { LoginFormComponent } from "./app/auth/login-form/login-form.component";
import { LoginComponent } from "./app/auth/login/login.component";
import { RegistrationFormComponent } from "./app/auth/registration-form/registration-form.component";
import { RegistrationComponent } from "./app/auth/registration/registration.component";
import { ResetPasswordFormComponent } from "./app/auth/reset-password-form/reset-password-form.component";
import { ResetPasswordTokenComponent } from "./app/auth/reset-password-token/reset-password-token.component";
import { ResetPasswordComponent } from "./app/auth/reset-password/reset-password.component";
import { TokenComponent } from "./app/auth/token/token.component";
import { Error403Component } from './app/help/403/error-403.component';
import { Error404Component } from './app/help/404/error-404.component';
import { InfoComponent } from "./app/help/info/info.component";
import { TourComponent } from "./app/help/tour/tour.component";
import { InviteIndexComponent } from "./app/invites/invite-index/invite-index.component";
import { InviteTableComponent } from "./app/invites/invite-table/invite-table.component";
import { InvoicesCreateComponent } from "./app/invoices/invoices-create/invoices-create.component";
import { InvoicesFormComponent } from "./app/invoices/invoices-form/invoices-form.component";
import { InvoicesIndexComponent } from "./app/invoices/invoices-index/invoices-index.component";
import { InvoicesTableComponent } from "./app/invoices/invoices-table/invoices-table.component";
import { InvoicesViewComponent } from "./app/invoices/invoices-view/invoices-view.component";
import { PhoneReportsFormComponent } from "./app/phone-reports/phone-reports-form/phone-reports-form.component";
import { PhoneReportsIndexComponent } from "./app/phone-reports/phone-reports-index/phone-reports-index.component";
import { PhoneReportsTableComponent } from "./app/phone-reports/phone-reports-table/phone-reports-table.component";
import { PhoneReportsWidgetComponent } from "./app/phone-reports/phone-reports-widget/phone-reports-widget.component";
import { PricingGroupCreateComponent } from "./app/pricing/general/pricing-group-create/pricing-group-create.component";
import { PricingGroupFormComponent } from "./app/pricing/general/pricing-group-form/pricing-group-form.component";
import { PricingGroupIndexComponent } from "./app/pricing/general/pricing-group-index/pricing-group-index.component";
import { PricingGroupTableComponent } from "./app/pricing/general/pricing-group-table/pricing-group-table.component";
import { PricingGroupUpdateComponent } from "./app/pricing/general/pricing-group-update/pricing-group-update.component";
import { PackagesIndexComponent } from "./app/pricing/packages/packages-index/packages-index.component";
import { SpecialPricingGroupClientComponent } from "./app/pricing/special/special-pricing-group-client/special-pricing-group-client.component";
import { SpecialPricingGroupCreateComponent } from "./app/pricing/special/special-pricing-group-create/special-pricing-group-create.component";
import { SpecialPricingGroupFormComponent } from "./app/pricing/special/special-pricing-group-form/special-pricing-group-form.component";
import { SpecialPricingGroupIndexComponent } from "./app/pricing/special/special-pricing-group-index/special-pricing-group-index.component";
import { SpecialPricingGroupTableComponent } from "./app/pricing/special/special-pricing-group-table/special-pricing-group-table.component";
import { SpecialPricingGroupUpdateComponent } from "./app/pricing/special/special-pricing-group-update/special-pricing-group-update.component";
import { SettingIndexComponent } from "./app/setting/setting-index/setting-index.component";
import { SettingValueComponent } from "./app/setting/setting-value/setting-value.component";
import { AuthGuard } from "./app/shared/auth-guard";
import { DeleteModal } from './app/shared/components/deletemodal/deletemodal.component';
import { TitleModal } from './app/shared/components/titlemodal/titlemodal.component';
import { UserApiService } from "./app/shared/services/api.service";
import { AuditLogService } from "./app/shared/services/audit-log.service";
import { InviteService } from "./app/shared/services/invite.service";
import { InvoiceService } from "./app/shared/services/invoice.service";
import { PhoneReportService } from "./app/shared/services/phone-report.service";
import { PricingService } from "./app/shared/services/pricing.service";
import { SessionsService } from './app/shared/services/sessions.service';
import { SettingService } from "./app/shared/services/setting.service";
import { StatisticsService } from './app/shared/services/statistics.service';
import { StopWordsService } from './app/shared/services/stop-words.service';
import { SuppliersService } from './app/shared/services/suppliers.service';
import { TemplatesService } from './app/shared/services/templates.service';
import { TestCaseTemplatesService } from './app/shared/services/test-case-template.service';
import { StopWordsIndexComponent } from './app/stop-words/stop-words-index/stop-words-index.component';
import { StopWordsTableComponent } from './app/stop-words/stop-words-table/stop-words-table.component';
import { TestCaseTemplateIndexComponent } from './app/test-case-templates/test-case-template-index/test-case-template-index.component';
import { TestCaseTemplateTableComponent } from './app/test-case-templates/test-case-template-table/test-case-template-table.component';
import { TestCaseTemplateUpdateComponent } from './app/test-case-templates/test-case-template-update/test-case-template-update.component';
import { TestResultCommentComponent } from "./app/test/test-result-comment/test-result-comment.component";
import { TextTemplateComponent } from "./app/test/text-template/text-template.component";

import { CurrencyPipe, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgGridModule } from "ag-grid-angular";
import { AnalyticsIndexComponent } from "./app/analytics/analytics-index/analytics-index.component";
import { AppscriptsCryptoWalletIndexComponent } from "./app/appscripts/appscripts-crypto-wallet/appscripts-payment-method-index/appscripts-crypto-wallet-index.component";
import {
    AppscriptsPaymentMethodIndexComponent
} from './app/appscripts/appscripts-payment-method/appscripts-payment-method-index/appscripts-payment-method-index.component';
import {
    AppscriptsPaymentRequestFilterComponent
} from './app/appscripts/appscripts-payment-request/appscripts-payment-request-filter/appscripts-payment-request-filter.component';
import {
    AppscriptsPaymentRequestFormComponent
} from './app/appscripts/appscripts-payment-request/appscripts-payment-request-form/appscripts-payment-request-form.component';
import {
    AppscriptsPaymentRequestIndexComponent
} from './app/appscripts/appscripts-payment-request/appscripts-payment-request-index/appscripts-payment-request-index.component';
import {
    AppscriptsPaymentRequestTableComponent
} from './app/appscripts/appscripts-payment-request/appscripts-payment-request-table/appscripts-payment-request-table.component';
import {
    AppscriptsSmsFilterComponent
} from './app/appscripts/appscripts-sms/appscripts-sms-filter/appscripts-sms-filter.component';
import {
    AppscriptsSmsIndexComponent
} from './app/appscripts/appscripts-sms/appscripts-sms-index/appscripts-sms-index.component';
import {
    AppscriptsSmsTableComponent
} from './app/appscripts/appscripts-sms/appscripts-sms-table/appscripts-sms-table.component';
import {
    AppscriptsUserFilterComponent
} from './app/appscripts/appscripts-user/appscripts-user-filter/appscripts-user-filter.component';
import {
    AppscriptsUserIndexComponent
} from './app/appscripts/appscripts-user/appscripts-user-index/appscripts-user-index.component';
import {
    AppscriptsUserTableComponent
} from './app/appscripts/appscripts-user/appscripts-user-table/appscripts-user-table.component';
import { MfaRecoveryFormComponent } from "./app/auth/mfa-recovery-form/mfa-recovery-form.component";
import { DashboardCounterWidgetComponent } from "./app/dashboard/dashboard-counter-widget/dashboard-counter-widget.component";
import { DashboardProfitWidgetComponent } from "./app/dashboard/dashboard-profit-widget/dashboard-profit-widget.component";
import { DashboardTestCountServiceWidgetComponent } from "./app/dashboard/dashboard-test-count-service-widget/dashboard-test-count-service-widget.component";
import { DashboardTestCountUserWidgetComponent } from "./app/dashboard/dashboard-test-count-user-widget/dashboard-test-count-user-widget.component";
import { DashboardTestCountWidgetComponent } from "./app/dashboard/dashboard-test-count-widget/dashboard-test-count-widget.component";
import { DashboardTestCountWorldWidgetComponent } from "./app/dashboard/dashboard-test-count-world-widget/dashboard-test-count-world-widget.component";
import { OfflineComponent } from "./app/help/offline/offline.component";
import { UpdateDetectComponent } from './app/help/update-detect/update-detect.component';
import { MainMenuComponent } from './app/main-menu/main-menu.component';
import { ConnectionsImportComponent } from "./app/sessions-suppliers/connections-import/connections-import.component";
import { ModalsWidgetComponent } from "./app/shared/components/modals-widget/modals-widget.component";
import {
    NotificationsWidgetComponent
} from './app/shared/components/notifications-widget/notifications-widget.component';
import { TableBase } from "./app/shared/components/table/table-base";
import { TableComponent } from './app/shared/components/table/table.component';
import { TruncatePipe } from './app/shared/pipes/truncate.pipe';
import { AnalyticService } from "./app/shared/services/analytic.service";
import { AppscriptsCryptoService } from "./app/shared/services/appscripts-crypto.service";
import { AppscriptsPaymentMethodService } from './app/shared/services/appscripts-payment-method.service';
import { AppscriptsPaymentRequestService } from './app/shared/services/appscripts-payment-request.service';
import { AppscriptsSmsService } from './app/shared/services/appscripts-sms.service';
import { AppscriptsUserService } from './app/shared/services/appscripts-user.service';
import { ConnectionsImportService } from "./app/shared/services/connections-import.service";
import { CustomHttpInterceptor } from './app/shared/services/custom-http-interceptor.service';
import { DatadogService } from './app/shared/services/datadog.service';
import { DestinationsService } from './app/shared/services/destinations.service';
import { MoTestingService } from "./app/shared/services/mo-testing.service";
import { ModalService } from "./app/shared/services/modal.service";
import { UsersService } from './app/shared/services/users.service';
import { DestinationsSelectComponent } from './app/test/destinations-select/destinations-select.component';
import { LntFormComponent } from './app/test/lnt/lnt-form/lnt-form.component';
import { LntIndexComponent } from './app/test/lnt/lnt-index/lnt-index.component';
import { LntResultsTableComponent } from './app/test/lnt/lnt-results-table/lnt-results-table.component';
import { LntSearchFormComponent } from './app/test/lnt/lnt-search-form/lnt-search-form.component';
import { MntFormComponent } from './app/test/mnt/mnt-form/mnt-form.component';
import { MntIndexComponent } from './app/test/mnt/mnt-index/mnt-index.component';
import { MntResultsTableComponent } from './app/test/mnt/mnt-results-table/mnt-results-table.component';
import { MntSearchFormComponent } from './app/test/mnt/mnt-search-form/mnt-search-form.component';
import { MoFormComponent } from "./app/test/mo/mo-form/mo-form.component";
import { MoIndexComponent } from "./app/test/mo/mo-index/mo-index.component";
import { MoNumberInputComponent } from "./app/test/mo/mo-number-input/mo-number-input.component";
import { MoResultsTableComponent } from "./app/test/mo/mo-results-table/mo-results-table.component";
import { MoSearchFormComponent } from "./app/test/mo/mo-search-form/mo-search-form.component";
import { RoutesImportComponent } from './app/test/routes-import/routes-import.component';
import { RoutesSelectComponent } from './app/test/routes-select/routes-select.component';
import { UdhTlvInputComponent } from './app/test/udh-tlv-input/udh-tlv-input.component';
import { AccountFormComponent } from './app/users/account-form/account-form.component';
import { AccountIndexComponent } from './app/users/account-index/account-index.component';
import { BalanceWidgetComponent } from './app/users/balance-widget/balance-widget.component';
import { MfaRecoveryCodesComponent } from "./app/users/mfa-recovery-codes/mfa-recovery-codes.component";
import { SubaccountFormComponent } from './app/users/subaccount-form/subaccount-form.component';
import { SubaccountsIndexComponent } from './app/users/subaccounts-index/subaccounts-index.component';
import { SubaccountsTableComponent } from './app/users/subaccounts-table/subaccounts-table.component';
import { UsersCreateComponent } from './app/users/users-create/users-create.component';
import { UsersFormComponent } from './app/users/users-form/users-form.component';
import { UsersIndexComponent } from './app/users/users-index/users-index.component';
import { UsersSelectComponent } from './app/users/users-select/users-select.component';
import { UsersTableComponent } from './app/users/users-table/users-table.component';
import { UsersUpdateComponent } from './app/users/users-update/users-update.component';

import { ContentTemplatesCreateComponent } from './app/content-templates/content-templates-create/content-templates-create.component';
import { ContentTemplatesFormComponent } from './app/content-templates/content-templates-form/content-templates-form.component';
import { ContentTemplatesImportComponent } from './app/content-templates/content-templates-import/content-templates-import.component';
import { ContentTemplatesIndexComponent } from './app/content-templates/content-templates-index/content-templates-index.component';
import { ContentTemplatesListComponent } from './app/content-templates/content-templates-list/content-templates-list.component';
import { ContentTemplatesTableComponent } from './app/content-templates/content-templates-table/content-templates-table.component';
import { ContentTemplatesUpdateComponent } from './app/content-templates/content-templates-update/content-templates-update.component';
import { SmsTextTemplatesTableComponent } from './app/sms-text-templates/sms-text-templates-table/sms-text-templates-table.component';

import { ScrollingModule } from "@angular/cdk/scrolling";
import { LicenseManager } from "ag-grid-enterprise";
import { RECAPTCHA_BASE_URL, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AnalyticsTableHeaderComponent } from "./app/analytics/analytics-table/analytics-table-header.component";
import { AnalyticsTableSearchHeaderComponent } from "./app/analytics/analytics-table/analytics-table-search-header.component";
import { AnalyticsTableComponent } from "./app/analytics/analytics-table/analytics-table.component";
import { GroupCellRenderer } from "./app/analytics/analytics-table/group-cell-renderer.component";
import { StatusRendererComponent } from "./app/analytics/analytics-table/status-renderer.component";
import { CoverageMapComponent } from "./app/analytics/covearge-map/coverage-map.component";
import { CoverageIndexComponent } from "./app/analytics/coverage-index/coverage-index.component";
import { CoverageTableActionsRendererComponent } from "./app/analytics/coverage-table/coverage-table-actions-renderer.component";
import { CoverageTableComponent } from "./app/analytics/coverage-table/coverage-table.component";
import { CallbackHistoryFilterComponent } from "./app/api/callback-history/callback-history-filter/callback-history-filter.component";
import { CallbackHistoryIndexComponent } from "./app/api/callback-history/callback-history-index/callback-history-index.component";
import {
    AppscriptsMoSmsFilterComponent
} from "./app/appscripts/appscripts-mo-sms/appscripts-mo-sms-filter/appscripts-mo-sms-filter.component";
import {
    AppscriptsMoSmsIndexComponent
} from "./app/appscripts/appscripts-mo-sms/appscripts-mo-sms-index/appscripts-mo-sms-index.component";
import {
    AppscriptsMoSmsTableComponent
} from "./app/appscripts/appscripts-mo-sms/appscripts-mo-sms-table/appscripts-mo-sms-table.component";
import { AuditFilterFormComponentV2 } from "./app/audit-log-v2/audit-filter-form/audit-filter-form.component";
import { AuditLogIndexComponentV2 } from './app/audit-log-v2/audit-log-index/audit-log-index.component';
import { DisabledSectionWidgetComponent } from "./app/help/disabled-section-widget/disabled-section-widget.component";
import { ProfileComponent } from './app/main-menu/profile/profile.component';
import { MoShortCodeListComponent } from './app/mo-shortcode/mo-short-code-list/mo-short-code-list.component';
import { MoShortcodeFormComponent } from './app/mo-shortcode/mo-shortcode-form/mo-shortcode-form.component';
import { MoShortcodeImportComponent } from "./app/mo-shortcode/mo-shortcode-import/mo-shortcode-import.component";
import { MoShortcodeIndexComponent } from './app/mo-shortcode/mo-shortcode-index/mo-shortcode-index.component';
import { MoShortcodeTableComponent } from './app/mo-shortcode/mo-shortcode-table/mo-shortcode-table.component';
import { BillingProgressComponent } from "./app/pricing/billing-progress/billing-progress.component";
import { SessionSupplierSearchComponent } from './app/sessions-suppliers/session-supplier-search/session-supplier-search.component';
import { ExpandComponent } from './app/sessions-suppliers/session-suppliers-table/expand/expand.component';
import { NoSupplierOverlayComponent } from './app/sessions-suppliers/session-suppliers-table/no-supplier-overlay/no-supplier-overlay.component';
import { SessionActionsComponent } from './app/sessions-suppliers/session-suppliers-table/session-actions/session-actions.component';
import { SupplierActionsComponent } from './app/sessions-suppliers/session-suppliers-table/supplier-actions/supplier-actions.component';
import { SupplierTableFooterComponent } from './app/sessions-suppliers/session-suppliers-table/supplier-table-footer/supplier-table-footer.component';
import { AgTooltipComponent } from './app/shared/components/ag-tooltip/ag-tooltip.component';
import { ConfirmedActionComponent } from "./app/shared/components/confirmed-action/confirmed-action.component";
import { SlideButtonComponent } from './app/shared/components/slide-button/slide-button.component';
import { DropdownDirective } from "./app/shared/directives/dropdown.directive";
import { ScrollSpyDirective } from './app/shared/directives/scroll-spy-directive.directive';
import { TooltipDirective } from './app/shared/directives/tooltip.directive';
import { InitialsPipe } from './app/shared/pipes/initials.pipe';
import { NumberFormatPipe } from "./app/shared/pipes/number-format.pipe";
import { ThousandSeparator } from './app/shared/pipes/thousandseparator.pipe';
import { BackendChannelService } from "./app/shared/services/backend-channel.service";
import { ConfirmedActionsInterceptor } from "./app/shared/services/confirmed-actions.interceptor";
import { ConfirmedActionsService } from "./app/shared/services/confirmed-actions.service";
import { CoverageService } from "./app/shared/services/coverage.service";
import { StatusPageService } from './app/shared/services/status-page.service';
import { UrlSwitcherInterceptor } from "./app/shared/services/url-switcher.interceptor";
import { LntContentTemplatesComponent } from './app/test/lnt/lnt-content-templates/lnt-content-templates.component';
import { LntActionsComponent } from './app/test/lnt/lnt-results-table/lnt-actions/lnt-actions.component';
import { MntActionsComponent } from './app/test/mnt/mnt-results-table/mnt-actions/mnt-actions.component';
import { VtIndexComponent } from "./app/test/vt/vt-index/vt-index.component";
import { VtResultsTableComponent } from "./app/test/vt/vt-results-table/vt-results-table.component";
import { VtFormComponent } from "./app/test/vt/vt-form/vt-form.component";
import { VtSearchFormComponent } from "./app/test/vt/vt-search-form/vt-search-form.component";
import { VoiceTestingService } from "./app/shared/services/voice-testing.service";
import { MoTextInputComponent } from './app/test/mo/mnt-text-input/mo-text-input.component';
import { DevicesTableComponent } from "./app/users/devices-table/devices-table.component";
import { UsersActionsComponent } from './app/users/users-table/users-actions/users-actions.component';
import { VtResultsTableActionsComponent } from "./app/test/vt/vt-results-table/vt-results-table-actions/vt-results-table-actions.component";
import { AppscriptsMtTierFormComponent } from "./app/appscripts/appscripts-mt-tier/appscripts-mt-tier-form/appscripts-mt-tier-form.component";
import { AppscriptsMtTierIndexComponent } from "./app/appscripts/appscripts-mt-tier/appscripts-mt-tier-index/appscripts-mt-tier-index.component";
import { AppscriptsMtTierTableComponent } from "./app/appscripts/appscripts-mt-tier/appscripts-mt-tier-table/appscripts-mt-tier-table.component";
import { AppscriptsBillingService } from "./app/shared/services/appscripts-billing.service";
import { UsersSearchComponent } from './app/users/users-search/users-search.component';
import { PhonesReportsTableActionsComponent } from './app/phone-reports/phone-reports-table/phones-reports-table-actions/phones-reports-table-actions.component';
import { SchedulerReportsSearchComponent } from './app/scheduler/scheduler-reports-search/scheduler-reports-search.component';
import { AppscriptsCliIndexComponent } from './app/appscripts/appscripts-cli/appscripts-cli-index/appscripts-cli-index.component';
import { AppscriptsCliFilterComponent } from './app/appscripts/appscripts-cli/appscripts-cli-filter/appscripts-cli-filter.component';
import { AppscriptsCliTableComponent } from './app/appscripts/appscripts-cli/appscripts-cli-table/appscripts-cli-table.component';
import { AppscriptsUserTableActionsComponent } from './app/appscripts/appscripts-user/appscripts-user-table/appscripts-user-table-actions/appscripts-user-table-actions.component';
import { VtResultsTableStatusComponent } from './app/test/vt/vt-results-table/vt-results-table-status/vt-results-table-status.component';
import { SchedulerTableActionsComponent } from './app/scheduler/scheduler-table/scheduler-table-actions/scheduler-table-actions.component';
import { SchedulerSearchComponent } from './app/scheduler/scheduler-search/scheduler-search.component';
import { SpecialPricingGroupTableActionsComponent } from './app/pricing/special/special-pricing-group-table/special-pricing-group-table-actions/special-pricing-group-table-actions.component';
import { SpecialPricingSubTableComponent } from './app/pricing/special/special-pricing-group-table/special-pricing-sub-table/special-pricing-sub-table.component';
import { ReasonForReportComponent } from './app/scheduler/scheduler-reports-table/reason-for-report/reason-for-report.component';
import { InvoiceTableActionsComponent } from './app/invoices/invoices-table/invoice-table-actions/invoice-table-actions.component';
import { MoResultsTableActionsComponent } from './app/test/mo/mo-results-table/mo-results-table-actions/mo-results-table-actions.component';
import { SpecialPricingGroupSearchComponent } from './app/pricing/special/special-pricing-group-search/special-pricing-group-search.component';
import { InvoicesSearchComponent } from './app/invoices/invoices-search/invoices-search.component';
import { AppscriptsSettingService } from "./app/shared/services/appscripts-setting.service";
import { AppscriptsSettingIndexComponent } from "./app/appscripts/appscripts-settings/appscripts-settings-index/appscripts-setting-index.component";
import { DashboardUserFilterComponent } from './app/dashboard/dashboard-index/dashboard-user-filter/dashboard-user-filter.component';

export function initApp(backendChannel: BackendChannelService, userService: UsersService) {
    return () => {
        return new Promise((resolve, reject) => {
            backendChannel.init().then(() => {
                userService.init().then(() => {
                    resolve(true)
                });
            });
        });
    }
}

if (environment.agGridLicenseKey) {
    LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        MainMenuComponent,
        UpdateDetectComponent,
        Breadcrumbs,
        PaginationComponent,
        DeleteModal,
        TitleModal,
        Progressbar,
        TableBase,
        TableComponent,
        FormErrors,
        // dashboard
        DashboardIndexComponent, DashboardTestCountWidgetComponent, DashboardTestCountServiceWidgetComponent, DashboardTestCountUserWidgetComponent, DashboardProfitWidgetComponent, DashboardCounterWidgetComponent, DashboardTestCountWorldWidgetComponent,
        // connection
        ConnectionsImportComponent,
        SessionFormComponent, SessionSupplierTableComponent, SessionCreateComponent, SessionSupplierIndexComponent, SessionUpdateComponent,
        // invoice
        InvoicesIndexComponent, InvoicesTableComponent, InvoicesFormComponent, InvoicesCreateComponent, InvoicesViewComponent, PackagesIndexComponent,
        // Api
        ApiSettingsComponent, CallbackHistoryIndexComponent, CallbackHistoryFilterComponent, CallbackHistoryTableComponent,
        // auth
        LoginComponent, LoginFormComponent, RegistrationComponent, RegistrationFormComponent, ResetPasswordComponent, ResetPasswordFormComponent, ResetPasswordTokenComponent, TokenComponent,
        MfaRecoveryCodesComponent, MfaRecoveryFormComponent,
        // supplier
        SupplierFormComponent, SupplierCreateComponent, SupplierUpdateComponent,
        // tariff
        PricingGroupIndexComponent, PricingGroupTableComponent, PricingGroupFormComponent, PricingGroupCreateComponent, PricingGroupUpdateComponent,
        SpecialPricingGroupIndexComponent, SpecialPricingGroupTableComponent, SpecialPricingGroupFormComponent, SpecialPricingGroupCreateComponent, SpecialPricingGroupUpdateComponent,
        SpecialPricingGroupClientComponent, BillingProgressComponent,
        // test case
        LntFormComponent, LntIndexComponent, RoutesSelectComponent, DestinationsSelectComponent, UdhTlvInputComponent,
        // test-case result
        LntResultsTableComponent, LntSearchFormComponent, TestResultCommentComponent,
        // test manual
        MntIndexComponent, MntResultsTableComponent, MntFormComponent, MntSearchFormComponent,
        // MO testing
        MoNumberInputComponent, MoIndexComponent, MoResultsTableComponent, MoFormComponent, MoSearchFormComponent, MoTextInputComponent,
        // voice testing
        VtIndexComponent, VtResultsTableComponent, VtResultsTableActionsComponent, VtFormComponent, VtSearchFormComponent,
        // analytics
        AnalyticsIndexComponent, AnalyticsTableComponent, GroupCellRenderer, AnalyticsTableHeaderComponent, AnalyticsTableSearchHeaderComponent, StatusRendererComponent,
        // coverage
        CoverageTableComponent, CoverageIndexComponent, CoverageTableActionsRendererComponent, CoverageMapComponent,
        // test case template
        TestCaseTemplateIndexComponent, TestCaseTemplateTableComponent, TestCaseTemplateUpdateComponent, TextTemplateComponent,
        // user
        AccountIndexComponent, AccountFormComponent, BalanceWidgetComponent, UsersFormComponent, UsersTableComponent, UsersCreateComponent, UsersUpdateComponent, UsersIndexComponent, SubaccountsTableComponent, SubaccountsIndexComponent, UsersSelectComponent, SubaccountFormComponent, InviteTableComponent, InviteIndexComponent, DevicesTableComponent,
        // stop words
        StopWordsTableComponent, StopWordsIndexComponent,
        // network
        NetworkIndexComponent, NetworkFormComponent, NetworkTableComponent,
        // region
        CountryIndexComponent, CountryTableComponent, CountryFormComponent,
        // appscripts
        AppscriptsUserTableComponent, AppscriptsUserIndexComponent, AppscriptsUserFilterComponent,
        AppscriptsPaymentRequestTableComponent, AppscriptsPaymentRequestFormComponent, AppscriptsPaymentRequestFilterComponent, AppscriptsPaymentRequestIndexComponent,
        AppscriptsSmsTableComponent, AppscriptsSmsFilterComponent, AppscriptsSmsIndexComponent,
        AppscriptsMoSmsTableComponent, AppscriptsMoSmsFilterComponent, AppscriptsMoSmsIndexComponent,
        AppscriptsPaymentMethodTableComponent, AppscriptsPaymentMethodFormComponent, AppscriptsPaymentMethodIndexComponent,
        AppscriptsBalanceFormComponent,
        AppscriptsCryptoWalletIndexComponent,
        AppscriptsMtTierTableComponent, AppscriptsMtTierFormComponent, AppscriptsMtTierIndexComponent,
        AppscriptsSettingIndexComponent,
        //phone reports
        PhoneReportsIndexComponent, PhoneReportsTableComponent, PhoneReportsWidgetComponent, PhoneReportsFormComponent,
        // scheduler
        SchedulerTableComponent, SchedulerIndexComponent, SchedulerFormComponent, SchedulerCreateComponent, SchedulerReportsTableComponent, SchedulerReportsIndexComponent,
        // setting
        SettingIndexComponent, SettingValueComponent,
        // audit
        AuditLogIndexComponent, AuditFilterFormComponent,
        AuditLogIndexComponentV2, AuditFilterFormComponentV2,
        // Connection Import 
        RoutesImportComponent,
        // pipes
        FilterTextPipe,
        InputSearch,
        SortPipe,
        ThousandSeparator,
        FileSizePipe,
        TruncatePipe,
        InputSearchDatePeriod,
        InputErrors,
        InputDuration,
        InputDateTime,
        InputFile,
        DurationPipe,
        // help
        TourComponent, InfoComponent, Error404Component, Error403Component,
        NotificationsWidgetComponent, OfflineComponent, DisabledSectionWidgetComponent,

        ModalsWidgetComponent,

        // Content templates 
        ContentTemplatesIndexComponent,
        ContentTemplatesCreateComponent,
        ContentTemplatesUpdateComponent,
        ContentTemplatesTableComponent,
        ContentTemplatesListComponent,
        ContentTemplatesFormComponent,
        ContentTemplatesImportComponent,
        SmsTextTemplatesTableComponent,
        LntContentTemplatesComponent,

        TooltipDirective,
        MoShortcodeIndexComponent,
        MoShortcodeTableComponent,
        MoShortcodeFormComponent,
        MoShortCodeListComponent,
        MoShortcodeImportComponent,
        ProfileComponent,
        InitialsPipe,
        NumberFormatPipe,
        ScrollSpyDirective,
        DropdownDirective,
        LntActionsComponent,
        AgTooltipComponent,
        ConfirmedActionComponent,
        SlideButtonComponent,
        MntActionsComponent,
        UsersActionsComponent,
        SessionActionsComponent,
        SupplierActionsComponent,
        SessionSupplierSearchComponent,
        MntActionsComponent,
        NoSupplierOverlayComponent,
        SupplierTableFooterComponent,
        ExpandComponent,
        UsersSearchComponent,
        PhonesReportsTableActionsComponent,
        SchedulerReportsSearchComponent,
        AppscriptsCliIndexComponent,
        AppscriptsCliFilterComponent,
        AppscriptsCliTableComponent,
        AppscriptsUserTableActionsComponent,
        VtResultsTableStatusComponent,
        SchedulerTableActionsComponent,
        SchedulerSearchComponent,
        SpecialPricingGroupTableActionsComponent,
        SpecialPricingSubTableComponent,
        ReasonForReportComponent,
        InvoiceTableActionsComponent,
        MoResultsTableActionsComponent,
        SpecialPricingGroupSearchComponent,
        InvoicesSearchComponent,
        DashboardUserFilterComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(routes, {
            useHash: true
        }),
        UiSwitchModule,
        HighchartsChartModule,
        AgGridModule,
        RecaptchaV3Module,
        DragDropModule,
        ScrollingModule,
    ],
    providers: [
        DatePipe,
        Title,
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'API_BASE_URL', useValue: environment.apiUrl },
        { provide: 'PROXY_API_BASE_URL', useValue: environment.proxyApiUrl },
        { provide: 'APPSCRIPTS_BASE_URL', useValue: environment.appscriptsUrl },
        { provide: 'STORAGE_PREFIX', useValue: environment.storagePrefix },
        { provide: 'STATUSPAGE_ID', useValue: environment.statusPageId },
        { provide: 'NETWORKS_ONLINE_URL', useValue: environment.networksOnlineUrl },
        { provide: 'NETWORK_UPDATES_URL', useValue: environment.networkUpdatesUrl },
        { provide: 'MO_NETWORKS_ONLINE_URL', useValue: environment.moNetworksOnlineUrl },
        { provide: 'MO_NETWORK_UPDATES_URL', useValue: environment.moNetworkUpdatesUrl },
        { provide: 'VOICE_NETWORKS_ONLINE_URL', useValue: environment.voiceNetworksOnlineUrl },
        { provide: 'VOICE_NETWORK_UPDATES_URL', useValue: environment.voiceNetworkUpdatesUrl },
        { provide: 'RELEASE_VERSION', useValue: environment.releaseVersion },
        { provide: 'BACKEND_CHANNEL_RESET_TAG', useValue: environment.backendChannelResetTag },
        { provide: 'CW_DEVICE_LOGS_URL', useValue: environment.cloudwatchDeviceLogsUrl },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
        { provide: RECAPTCHA_BASE_URL, useValue: environment.recaptchaBaseUrl, }, // because google does not work in China ...
        LocalStorageService,
        SessionsService,
        SuppliersService,
        ConnectionsImportService,
        TemplatesService,
        DestinationsService,
        CountryService,
        InvoiceService,
        ValidationService,
        PhoneReportService,
        SettingService,
        InviteService,
        AuditLogService,
        LiveNumberTestingService,
        ManualNumberTestingService,
        MoTestingService,
        AnalyticService,
        CoverageService,
        TestCaseTemplatesService,
        VoiceTestingService,
        SchedulerService,
        StatisticsService,
        StopWordsService,
        UserApiService,
        CallbackHistoryService,
        StatusPageService,

        NetInfoService,
        PricingService,
        AppscriptsUserService,
        AppscriptsPaymentRequestService,
        AppscriptsPaymentMethodService,
        AppscriptsSmsService,
        AppscriptsCryptoService,
        AppscriptsBillingService,
        AppscriptsSettingService,

        UsersService,

        NotificationService,
        ModalService,
        BreadcrumbService,
        AuthGuard,
        ConfirmedActionsService,
        BackendChannelService,

        DatadogService,
        ConfirmedActionsInterceptor,
        CustomHttpInterceptor,
        CurrencyPipe,
        NumberFormatPipe,

        { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } },
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [BackendChannelService, UsersService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlSwitcherInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ConfirmedActionsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MainModule { }
