import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../shared/components/breadcrumbs/breadcrumbs';
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { MoFormComponent } from '../mo-form/mo-form.component';
import { MoResultsTableComponent } from "../mo-results-table/mo-results-table.component";
import { MoSearchFormComponent } from "../mo-search-form/mo-search-form.component";
import { MoTestingResult } from "../../../shared/models/mo-testing.model";

@Component({
    selector: 'app-mo-index',
    templateUrl: 'mo-index.component.html',
    styleUrls: ['../../mnt/mnt-index/mnt-index.component.scss'],
})

export class MoIndexComponent implements OnInit, AfterViewInit {

    @ViewChild(MoResultsTableComponent, {static: false}) resultsTable: MoResultsTableComponent;
    @ViewChild(MoFormComponent, {static: false}) form: MoFormComponent;
    @ViewChild(MoSearchFormComponent, {static: false}) filter: MoSearchFormComponent;

    pauseUpdateForm: boolean = false;
    pauseUpdateResults: boolean = false;

    testingDisabled = null;

    loading = false;

    constructor(
        public breadcrumbs: BreadcrumbService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        public userService: UsersService,
        titleService: Title
    ) {
        titleService.setTitle('MO testing');
    }

    afterSave(res) {
        this.pauseUpdateResults = false;
        this.resultsTable.currentPageNumber = 1;
        this.resultsTable.update();
    }

    onSubmitFilter(data) {
        if (this.resultsTable.loading) {
            return;
        }
        this.pauseUpdateResults = true;
        this.resultsTable.searchParams = data;
        this.resultsTable.currentPageNumber = 1;
        this.resultsTable.update();
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe(queryParams => {
            if (queryParams.ids) {
                const interval = setInterval(() => {
                    if (!this.resultsTable) {
                        return;
                    }
                    clearInterval(interval);
                    this.resultsTable.searchParams = {
                        ids: queryParams.ids.split(',').map(_ => parseInt(_.trim()))
                    };
                    this.resultsTable.currentPageNumber = 1;
                    this.resultsTable.update();
                }, 1000);

            }
        });
    }

    onRepeat(testCaseResult: MoTestingResult) {
        this.form.loading = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.form.reset();
        this.form.loadTestCase(testCaseResult.testGroupId);
    }
}
