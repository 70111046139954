import { BaseCollection } from "./base-collection.model";

export interface LoginCredentials {
    username: string
    password: string
    mfaCode: string
    rememberDevice: boolean
}

export interface MfaCredentials {
    username: string
    recoveryCode: string
}

export interface MfaSetup {
    recoveryCodes: string[]
    qrCode: string
    secret: string
}

export interface BasicUser {
    userId: number;
    id: number,
    fullName: string,
    username: string,
    email: string
}

export interface BasicUserCollection extends BaseCollection {
    content: BasicUser[]
}

export interface UserAdmin {
    id: number,
    fullName: string,
    username: string,
    email: string,
    parentEmail: string,
    parentId: number,
    companyName: string,
    lastActive: string,
    paymentType: string,
    balance: number,
    rankingIndex: number,
    enabled: boolean,
    mfaEnabled: boolean,
    hasSmppInfrastructure: boolean,
    ownerUserEmail: string,
    role: string,
    emailVerified: boolean | string,
    countSubaccounts: number,
    isDeputy: boolean,
    moEnabled: boolean,
    voiceEnabled: boolean
}

export interface UserAdminWithTestCount extends UserAdmin {
    testCount?: number
}

export interface UserAdminCollection extends BaseCollection {
    content: UserAdmin[]
}

export class Role {
    static ADMIN = 'admin';
    static MAIN = 'mainaccount';
    static DEPUTY = 'deputy';
    static SUB = 'subaccount';
    static GUEST = 'guest';
}

export class PaymentType {
    static PREPAID = 'PREPAID';
    static POSTPAID = 'POSTPAID';
}

export type Roles = 'admin' | 'mainaccount' | 'deputy' | 'manager' | 'subaccount'

export interface AuthUser {
    id: number;
    role: Roles;
    parentId: number;
    paymentType: string;
    impersonated: boolean;
    enabled: boolean;
    email: string;
    username: string;
    fullName: string;
    ownerEmail?: string;
    showAllResults?: boolean;
    defaultLntTemplate?: number;
    testTtlMin?: number;
    testTtl?: number;
    companyName?: string;
    phone?: string;
    websiteUrl?: string;
    vatNumber?: string;
    emailForAlert?: string;
    emailForInvoice?: string;
    countryName?: string;
    addressCity?: string;
    addressZip?: string;
    addressStreet?: string;
    minBalance?: number;
    testRetriesEnabled?: boolean;
    mfaEnabled?: boolean;
    ipWhitelistEnabled?: boolean;
    ipWhitelistAddressList?: string;
    moAllowedNumbersEnabled?: boolean;
}

export interface AccountUser {
    emailForAlert: string;
    role: string;
    addressZip: string;
    companyName: string;
    fullName: string;
    testTtl: number;
    paymentType: string;
    password?: string;
    emailForInvoice: string;
    minBalance: number;
    phone: string;
    websiteUrl: string;
    addressStreet: string;
    id: number;
    countryName: string;
    email: string;
    testRetriesEnabled: boolean;
    username: string;
    vatNumber: string;
    addressCity: string;
    mfaEnabled?: boolean;
    ipWhitelistEnabled: boolean;
    ipWhitelistAddressList: string;
}

export interface UpdatePasswordModel {
    token?: string,
    currentPassword?: string,
    newPassword?: string,
    repeatNewPassword?: string
}

export interface NewEmailModel {
    newEmail: string,
}

export interface UserFormData {
    id: number,
    fullName: string,
    email: string,
    password: string,
    paymentType: string,
    enabled: boolean,
    emailVerified: boolean,
    sendVerificationEmail: boolean,
    username: string,
    companyName: string,
    websiteUrl: string,
    countryName: string,
    userRole: string,
    phone: string,
    emailForAlert: string,
    emailForInvoice: string,
    addressCity: string,
    addressStreet: string,
    addressZip: string,
    vatNumber: string,
    vatPercentage: number,
    applyVat: boolean,
    testRetriesEnabled: boolean,
    rankingIndex: number,
    parentId: number,
    ownerId: number,
    testTtl: number,
    source: string,
    convertedBy: number | any,
    salesRepresentative: number | any,
    parent: BasicUser,
    owner: BasicUser,
    showAllResults: boolean,
    ipWhitelistEnabled: boolean,
    ipWhitelistAddressList: string,
    comment: string
}

export interface UserUpdateByAdminModel {
    userRole: string,
    username: string,
    fullName: string,
    companyName: string,
    parent?: BasicUser,
    owner?: BasicUser,
    phone: string,
    websiteUrl?: string,
    rankingIndex: number,
    vatNumber?: string,
    vatPercentage?: number,
    applyVat?: boolean,
    email: string,
    emailForAlert?: string,
    emailForInvoice?: string,
    paymentType: string,
    countryName: string,
    addressCity?: string,
    addressZip?: string,
    addressStreet?: string,
    password?: string,
    emailVerified: boolean,
    sendVerificationEmail: boolean,
    testTtl: number,
    enabled: boolean,
    testRetriesEnabled: boolean,
    source: string,
    convertedBy: BasicUser,
    salesRepresentative: BasicUser,
    ipWhitelistEnabled: boolean,
    ipWhitelistAddressList: string,
    comment: string
}

export interface UserCreateByAdminModel {
    companyInformation: {
        id: number,
        companyName: string,
        websiteUrl: string,
        countryName: string,
        vatNumber: string,
        vatPercentage?: number,
        applyVat?: boolean,
        hasSmppInfrastructure: boolean
    },
    userProperties: {
        paymentType: string,
        emailVerified: boolean,
        testRetriesEnabled: boolean,
        showAllResults: boolean,
        defaultLntTemplate: 0,
        defaultMtTemplate: 0,
        minBalance: 0,
        rankingIndex: number,
        testTtl: number,
        ownerId: number,
        parentId: number,
        convertedById: number,
        salesRepresentativeId: number,
        source: string,
        comment: string
    },
    userRole: string,
    username: string,
    password: string,
    fullName: string,
    email: string,
    phone: string,
    addressCity: string,
    addressZip: string,
    addressStreet: string,
    countryName: string,
    emailForAlert: string,
    emailForInvoice: string,
    enabled: boolean,
    sendVerificationEmail: boolean
}

export interface RegistrationModel {
    username: string,
    password: string,
    fullName: string,
    email: string,
    phone: string,
    addressCity: string,
    addressZip: string,
    addressStreet: string,
    countryName: string,
    emailForAlert: string,
    emailForInvoice: string,
    source: string,
    recaptchaToken: string,
    companyInformation: {
        companyName: string,
        websiteUrl: string,
        countryName: string,
        vatNumber: string
    }
}

export interface ZapierModel extends Partial<
    Pick<RegistrationModel, 'username' | 'fullName' | 'email' | 'phone' | 'countryName' | 'source' | 'companyInformation'>
    > {
    queryString?: string;
}
export interface RegistrationByInviteModel {
    username: string,
    password: string,
    fullName: string,
    phone: string,
    countryName: string,
    email?: string
}

export interface SubaccountInviteResponseModel {
    email: string;
    enabled: boolean;
    parentId: number;
    paymentType: string;
    role: string;
    userId: number;
}

export interface TrustedDevice {
    id: number,
    createdAt: string,
    expireAt: string,
    lastLoginAt: string,
    lastIpAddress: string,
    lastClientAgent: string,
    internalDeviceId: string,
}

export interface TrustedDeviceCollection extends BaseCollection {
    content: TrustedDevice[];
}

export function formToCreateModel(data: UserFormData): UserCreateByAdminModel {
    let newData = Object.assign({}, data);
    if (newData.testTtl) {
        newData.testTtl = parseInt(String(newData.testTtl)) * 60;
    }

    return {
        companyInformation: {
            id: null,
            companyName: newData.companyName,
            websiteUrl: newData.websiteUrl,
            countryName: newData.countryName,
            vatNumber: newData.vatNumber,
            vatPercentage: newData.vatPercentage ? newData.vatPercentage : 0.0,
            applyVat: newData.applyVat ? newData.applyVat : false,
            hasSmppInfrastructure: false
        },
        userProperties: {
            paymentType: newData.paymentType,
            emailVerified: newData.emailVerified,
            testRetriesEnabled: newData.testRetriesEnabled,
            showAllResults: newData.showAllResults,
            defaultLntTemplate: 0,
            defaultMtTemplate: 0,
            minBalance: 0,
            rankingIndex: newData.rankingIndex,
            testTtl: newData.testTtl,
            ownerId: newData.owner ? newData.owner.id : null,
            parentId: newData.parent ? newData.parent.id : null,
            convertedById: newData.convertedBy ? newData.convertedBy.id : null,
            salesRepresentativeId: newData.salesRepresentative ? newData.salesRepresentative.id : null,
            source: newData.source !== "" ? newData.source : null,
            comment: newData.comment ? newData.comment : null
        },
        userRole: newData.userRole,
        username: newData.username,
        password: newData.password,
        fullName: newData.fullName,
        email: newData.email,
        phone: newData.phone,
        addressCity: newData.addressCity,
        addressZip: newData.addressZip,
        addressStreet: newData.addressStreet,
        countryName: newData.countryName,
        emailForAlert: newData.emailForAlert,
        emailForInvoice: newData.emailForInvoice,
        enabled: newData.enabled,
        sendVerificationEmail: newData.sendVerificationEmail,
    }
}

export function formToUpdateModel(data: UserFormData): UserUpdateByAdminModel {
    let newData = Object.assign({}, data);

    if (newData.testTtl) {
        newData.testTtl = parseInt(String(newData.testTtl)) * 60;
    }

    return {
        userRole: newData.userRole,
        username: newData.username,
        fullName: newData.fullName,
        companyName: newData.companyName,
        parent: newData.parent ? newData.parent : null,
        owner: newData.owner ? newData.owner : null,
        phone: newData.phone,
        websiteUrl: newData.websiteUrl ? newData.websiteUrl : null,
        rankingIndex: newData.rankingIndex,
        vatNumber: newData.vatNumber ? newData.vatNumber : null,
        vatPercentage: newData.vatPercentage ? newData.vatPercentage : 0.0,
        applyVat: newData.applyVat ? newData.applyVat : false,
        email: newData.email,
        emailForAlert: newData.emailForAlert ? newData.emailForAlert : null,
        emailForInvoice: newData.emailForInvoice ? newData.emailForInvoice : null,
        paymentType: newData.paymentType,
        countryName: newData.countryName,
        addressCity: newData.addressCity ? newData.addressCity : null,
        addressZip: newData.addressZip ? newData.addressZip : null,
        addressStreet: newData.addressStreet ? newData.addressStreet : null,
        password: newData.password ? newData.password : null,
        emailVerified: newData.emailVerified,
        sendVerificationEmail: false,
        testTtl: newData.testTtl,
        enabled: newData.enabled,
        testRetriesEnabled: newData.testRetriesEnabled,
        source: newData.source,
        convertedBy: newData.convertedBy ? newData.convertedBy : null,
        salesRepresentative: newData.salesRepresentative ? newData.salesRepresentative : null,
        ipWhitelistAddressList: newData.ipWhitelistAddressList ? newData.ipWhitelistAddressList : null,
        ipWhitelistEnabled: newData.ipWhitelistEnabled,
        comment: newData.comment ? newData.comment : null
    };
}

export interface UserSearchEvent {
    searchString?: string;
    enabled?: boolean;
    roles?: string | string[];
    owner?: string;
    company?: string;
    paymentType?: string;
    comment?: string;
}

export interface UserTestCount { date: string, mainaccountId: number, testBilled: number, testSubmitted: number }

export class TrustedDeviceRequestParams {

    size: number = 20;
    page: number = 1;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

